<template>
  <v-card>
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title style="color: white">
        <div>รายการแพ็คเกจและสินค้า</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          color="error"
          :disabled="arrayCourse.length == 0 && arrayProduct.length == 0"
          icon
          @click="dialogClears = true"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </v-toolbar>

    <div class="px-1 py-2 custom-layout">
      <v-col v-if="arrayCourse.length != 0 || arrayProduct.length != 0">
        <div>
          <v-row v-for="(course, index) in arrayCourse" :key="index + 'c'" class="pl-2">
            <div class="col-3">
              <div>
                <img
                  class="img-member rounded"
                  :src="utils.getImage(course.product_picture)"
                  @error="utils.imageError"
                />
              </div>
              <span class="cursor-pointer text-sm text--red" @click="removeCourse(index)">
                <v-icon small color="#FF0000">mdi-close </v-icon>ลบ
              </span>
            </div>

            <div class="col-8 pl-5">
              <div class="text-truncate">
                {{ course.name_th }}
              </div>
              <div class="text-xs d-flex align-center justify-space-between  ">
                <div>ประเภท : แพ็คเกจ</div>
                <div class="inputw150">
                  <!-- <v-select
                    class=""
                    v-model="course.sales"
                    :items="course.salesList"
                    item-text="text"
                    item-value="id"
                    label="พนักงานขาย"
                    dense
                    hide-details
                    solo
                    :disabled="course.isDisabledSale"
                    @change="handleDisable(course, 'isDisabledSale')"
                  ></v-select> -->
                  <div v-if="course.sales" class="custom-icon-close" @click="handleChangeOption(course, 'sales')">
                    <v-img width="15" src="@/assets/images/icons-close-black.png" />
                  </div>
                </div>
              </div>
              <div class="text-xs d-flex align-center justify-space-between mt-1">
                <div>จำนวน : 1 {{ course.unit }}</div>
                <div class="inputw150">
                  <!-- <v-select
                    class=""
                    v-model="course.provider"
                    :items="course.providerList"
                    item-text="text"
                    item-value="id"
                    label="ผู้ให้บริการ"
                    dense
                    hide-details
                    solo
                    :disabled="course.isDisabledProvider"
                    @change="handleDisable(course, 'isDisabledProvider')"
                  ></v-select> -->
                  <div v-if="course.provider" class="custom-icon-close" @click="handleChangeOption(course, 'provider')">
                    <v-img width="15" src="@/assets/images/icons-close-black.png" />
                  </div>
                </div>
              </div>
              <div v-if="course.down_payment == 1" class="text-md error--text mt-1">
                มัดจำ : {{ course.down_price + 'THB' }}
              </div>
              <div class="mt-2">
                <div>
                  <div class="text-md">{{ formatInt(course.price) }} THB</div>
                </div>
              </div>
            </div>
            <div class="w-100 col-12">
              <div>ระบุชื่อผู้ได้รับคอมมิชชั่นการขาย</div>
              <div class="mt-2">
                <input
                  type="radio"
                  style="height: 15px; width: 15px"
                  value="salesList"
                  :name="'serviceType' + index"
                  id="sales"
                  v-model="course.serviceType"
                />
                <label for="sales" class="ml-2">พนักงานขาย</label>
                <input
                  type="radio"
                  style="height: 15px; width: 15px"
                  value="providerList"
                  :name="'serviceType' + index"
                  class="ml-4"
                  id="doctors"
                  v-model="course.serviceType"
                />
                <label for="doctors" class="ml-2">ผู้ให้บริการ</label>
              </div>
              <v-select
                class="mt-2"
                v-model="course.idProvider"
                :items="course[course.serviceType]"
                item-text="text"
                item-value="id"
                label="กรุณาเลือก"
                dense
                hide-details
                solo
              ></v-select>
            </div>
          </v-row>
          <hr class="my-3" v-show="arrayCourse.length > 0 && arrayProduct.length > 0" />
          <v-row v-for="(product, index) in arrayProduct" :key="index" class="pl-2">
            <div class="col-3">
              <div>
                <img
                  class="img-member rounded"
                  :src="utils.getImage(product.product_picture)"
                  @error="utils.imageError"
                />
              </div>
              <span class="cursor-pointer text-sm text--red" @click="removeProduct(index)">
                <v-icon small color="#FF0000">mdi-close </v-icon>ลบ
              </span>
            </div>

            <div class="col-8 pl-5">
              <div class="text-truncate">
                {{ product.name_th }}
              </div>
              <div class="text-xs">ประเภท: สินค้า</div>
              <div class="text-xs">
                จำนวน:
                <v-btn class="mx-2" fab dark x-small color="primary" @click="minusValue(index)">
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
                <b> {{ product.value }} </b>
                <v-btn class="mx-2" fab dark x-small color="primary" @click="plusValue(index)">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
                {{ product.unit }}
              </div>
              <div>
                <div class="text-md">{{ formatInt(product.price) }} THB</div>
              </div>
            </div>
          </v-row>
        </div>
        <div>
          <div class="row pt-4">
            <div class="col text-left font-weight-bold">ยอดรวม</div>
            <div class="col text-right font-weight-bold">{{ formatInt(calculatePrice) }} บาท</div>
          </div>
          <div class="row">
            <div class="col text-left font-weight-bold"><b>ราคาสุทธิ</b></div>
            <div class="col text-right">
              <b class="text-xl" style="color: #d9896c"> {{ formatInt(calculateTotal) }} บาท</b>
            </div>
          </div>
        </div>
        <div class="text-center pt-5">
          <v-btn
            type="button"
            :disabled="userId"
            @click="handleCreateOrder"
            class="primary text-sm px-5 rounded-lg w-full"
          >
            สร้างออเดอร์
          </v-btn>
        </div>
      </v-col>
      <div v-else class="text-center">
        <v-img src="@/assets/images/pages/emptycart.png" />
        <b class="py-5">เลือกแพ็คเกจหรือสินค้าเพื่อทำรายการ</b>
      </div>
    </div>
    <v-dialog v-model="dialogClears" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5 text-center"> คุณต้องการเคลียร์รายการแพ็คเกจและสินค้า ? </v-card-title>
        <v-card-text class="text-center">
          <v-icon class="text-h1" color="error">mdi-delete-outline </v-icon>
        </v-card-text>
        <v-card-actions class="mx-5">
          <v-btn color="error" @click="dialogClears = false"> ยกเลิก </v-btn>
          <v-btn color="success" @click="handleRemoveOrder()"> ตกลง </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- modall no address -->
    <v-dialog v-model="dialogAddress" persistent max-width="290">
      <v-card>
        <v-card-title class="text-xl text-center"> กรุณากรอกข้อมูลใบกำกับภาษีก่อนทำรายการ </v-card-title>
        <v-card-text class="text-center">
          <v-icon class="text-h1" size="100" color="#D8DCDF">mdi-file </v-icon>
        </v-card-text>
        <v-card-actions class="mx-5">
          <v-btn color="primary" block @click="handleGoForm"> ตกลง </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    {{ checkData }}
  </v-card>
</template>

<script>
import numeral from 'numeral'
import utils from '@/helper/utils'
export default {
  props: ['courseSend', 'productSend'],
  data() {
    return {
      total_price: '',
      total_pay: '',
      arrayCourse: this.courseSend,
      arrayProduct: this.productSend,
      dialogClears: false,
      dialogAddress: false,
      isDisabledSale: false,
      isDisabledProvider: false,
      serviceType: 'salesList',
      idProvider: '',
      utils: utils,
    }
  },
  methods: {
    formatFloat(number) {
      return numeral(number).format('0,0.00')
    },
    formatInt(number) {
      return numeral(number).format('0,0')
    },
    removeCourse(index) {
      this.arrayCourse.splice(index, 1)
      this.$store.commit('SET_COURSE_ORDER', this.arrayCourse)
    },
    removeProduct(index) {
      this.arrayProduct.splice(index, 1)
      this.$store.commit('SET_PRODUCT_ORDER', this.arrayProduct)
    },
    handleChangeOption(item, key) {
      item.isDisabledProvider = false
      item.isDisabledSale = false
      item[key] = ''
      this.$forceUpdate()
    },
    handleDisable(item, key) {
      item.isDisabledProvider = true
      item.isDisabledSale = true
      item[key] = false
      this.$forceUpdate()
    },
    minusValue(i) {
      if (this.arrayProduct[i].value > 1) {
        this.arrayProduct[i].value = parseInt(this.arrayProduct[i].value) - 1
        this.arrayProduct[i].price_total = this.arrayProduct[i].price * this.arrayProduct[i].value
        this.arrayProduct[i].price_pay = this.arrayProduct[i].price * this.arrayProduct[i].value
        this.arrayProduct.splice(this.arrayProduct.length)
        this.$store.commit('SET_PRODUCT_ORDER', this.arrayProduct)
      }
    },
    plusValue(i) {
      this.arrayProduct[i].value = parseInt(this.arrayProduct[i].value) + 1
      this.arrayProduct[i].price_total = this.arrayProduct[i].price * this.arrayProduct[i].value
      this.arrayProduct[i].price_pay = this.arrayProduct[i].price * this.arrayProduct[i].value
      this.arrayProduct.splice(this.arrayProduct.length)
      this.$store.commit('SET_PRODUCT_ORDER', this.arrayProduct)
    },
    handleCreateOrder() {
      const listData = []

      this.arrayCourse.map(course => {
        let salesName = '-'
        let providerName = '-'
        if (course.serviceType == 'salesList') {
          course.sales = course.idProvider
        } else {
          course.provider = course.idProvider
        }
        course.salesList.forEach(i => {
          if (i.id === course.sales) {
            salesName = `${i.prefix_name ? i.prefix_name + ' ' : ''}${i.fname} ${i.lname}`
          }
        })
        course.providerList.forEach(i => {
          if (i.id === course.provider) {
            providerName = `${i.prefix_name ? i.prefix_name + ' ' : ''}${i.fname} ${i.lname}`
          }
        })
        Object.assign(course, {
          discount_type: 1,
          discount_price: '',
          price_after_all: course.price_pay,
          discount_cal: 0,
          providerName,
          salesName,
        })
        listData.push(course)
      })
      this.arrayProduct.map(product => {
        Object.assign(product, {
          discount_type: 1,
          discount_price: '',
          price_after_all: product.price_pay,
          discount_cal: 0,
        })

        listData.push(product)
      })

      const data = {
        orders: listData,
        total_pay: this.total_pay,
        total_price: this.total_price,
      }
      this.$store.commit('SET_COURSE_ORDER', this.arrayCourse)
      this.$store.commit('SET_PRODUCT_ORDER', this.arrayProduct)
      this.$store.commit('SET_ORDER', data)

      this.getClinicAddress()
    },

    getClinicAddress() {
      this.$store.dispatch('getClinicAddress').then(res => {
        if (res.length == 0) {
          this.dialogAddress = true
        } else {
          this.$router.push('/openbill/detail')
        }
      })
    },
    handleGoForm() {
      this.$router.push('/billing-form')
    },
    handleRemoveOrder() {
      this.$store.commit('SET_ORDER', '')
      this.$store.commit('SET_COURSE_ORDER', '')
      this.$store.commit('SET_PRODUCT_ORDER', '')
      this.arrayCourse = []
      this.arrayProduct = []
      this.dialogClears = false
      this.$root.$emit('clearSelected')
    },
  },
  computed: {
    calculatePrice() {
      var totalC = 0
      var totalP = 0
      var totalC_all = 0
      this.arrayCourse.forEach(function(item) {
        // if (item.down_payment == 1) {
        //   totalC += item.down_price
        // } else {
        //   totalC += item.price
        // }
        // totalC_all += item.price
        totalC += item.price
      })
      this.arrayProduct.forEach(function(item) {
        totalP += item.price * item.value
      })
      this.order_list = {
        net_price: totalC + totalP,
      }
      this.total_price = totalC + totalP
      return totalC + totalP
    },
    calculateTotal() {
      this.total_pay = this.calculatePrice
      return this.calculatePrice
    },
    checkData() {
      if (this.productSend != '') {
        this.arrayProduct = this.productSend
      } else if (this.$store.getters.getProduct != '') {
        this.arrayProduct = this.$store.getters.getProduct
      }
      if (this.courseSend != '') {
        this.arrayCourse = this.courseSend
      } else if (this.$store.getters.getCourse != '') {
        this.arrayCourse = this.$store.getters.getCourse
      }
    },
    userId() {
      if (localStorage.getItem('user_id') == 'undefined') {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    this.$root.$on('resetOrder', () => {
      this.handleRemoveOrder()
    })
    if (this.$store.getters.getProduct != '') {
      this.arrayProduct = this.productSend
    } else {
      this.arrayProduct = this.$store.getters.getProduct
    }

    if (this.$store.getters.getCourse != '') {
      this.arrayCourse = this.courseSend
    } else {
      this.arrayCourse = this.$store.getters.getCourse
    }
    this.arrayProduct = this.$store.getters.getProduct.length == 0 ? this.productSend : this.$store.getters.getProduct
    this.arrayCourse = this.$store.getters.getCourse.length == 0 ? this.courseSend : this.$store.getters.getCourse
    this.arrayCourse.forEach(i => {
      i.sales = ''
      i.provider = ''
      i.serviceType = 'salesList'
    })
  },
}
</script>
<style scoped>
.img-member {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
</style>
<style>
.inputw150 {
  width: 180px !important;
  position: relative;
}
.custom-icon-close {
  position: absolute;
  right: 38px;
  top: 11px;
  cursor: pointer;
  opacity: 0.4;
  z-index: 7;
}
</style>
