<template>
  <v-row>
    <v-col>
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title style="color: white">แพ็คเกจ/สินค้า</v-toolbar-title>
          <v-text-field
            v-if="tab == 0"
            style="border-radius: 13px; background-color: white"
            dense
            outlined
            clearable
            append-icon="mdi-magnify"
            class="app-bar-search flex-grow mx-3"
            hide-details
            placeholder="ค้นหาแพ็คเกจ"
            v-model="searchCourse"
          ></v-text-field>
          <v-text-field
            v-else
            style="border-radius: 13px; background-color: white"
            dense
            outlined
            clearable
            append-icon="mdi-magnify"
            class="app-bar-search flex-grow mx-3"
            hide-details
            placeholder="ค้นหาสินค้า"
            v-model="searchProduct"
            @click:clear="searchProduct = ''"
          ></v-text-field>
        </v-toolbar>
        <v-tabs vertical v-model="tab">
          <v-tab key="0">
            <v-icon left> mdi-briefcase-check </v-icon>
            แพ็คเกจ
          </v-tab>
          <v-tab key="1">
            <v-icon left> mdi-alpha-p-circle </v-icon>
            สินค้า
          </v-tab>

          <!-- course -->
          <v-tab-item>
            <v-card flat>
              <v-card-title> แพ็คเกจ </v-card-title>
              <v-data-table
                :headers="headersCourse"
                :items="course"
                :search="searchCourse"
                :page.sync="pageCourse"
                :sort-by.sync="sortByCourse"
                :sort-desc.sync="sortDescCourse"
                :items-per-page="itemsPerPageCourse"
                @page-count="pageCountCourse = $event"
                hide-default-footer
              >
                <template v-slot:no-data>
                  <span class="text-center">ยังไม่มีข้อมูล</span>
                </template>

                <template v-slot:no-results>
                  <div class="text-center">ไม่พบข้อมูล</div>
                </template>
                <template v-slot:[`item.name_th`]="{ item }">
                  <v-row class="flex-nowrap items-center align-center">
                    <img
                      :src="utils.getImage(item.product_picture)"
                      :alt="item.name_th"
                      @error="utils.imageError"
                      class="rounded img-member ml-2"
                    />

                    <div class="font-weight-bold text-truncate pl-2" style="max-width: 180px">
                      {{ item.name_th }}
                    </div>
                  </v-row>
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  <div class="row items-center py-5 pl-2 success--text">
                    {{ formatInt(item.price) }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div class="row items-center py-5 pl-2">
                    <div class="col">
                      <v-btn depressed color="primary" small @click="handheldAddCourse(item)"> เพิ่ม </v-btn>
                    </div>
                  </div>
                </template>
              </v-data-table>
              <div class="d-flex flex-row-reverse pt-2">
                <v-pagination
                  v-model="pageCourse"
                  :length="pageCountCourse"
                  :total-visible="7"
                  color="primary"
                ></v-pagination>
              </div>
            </v-card>
          </v-tab-item>

          <!-- สินค้า -->
          <v-tab-item>
            <v-card flat>
              <v-card-title> สินค้า </v-card-title>
              <v-data-iterator
                :headers="headersProduct"
                :items="product"
                :search="searchProduct"
                :page.sync="pageProduct"
                :sort-by.sync="sortByProduct"
                :sort-desc.sync="sortDescProduct"
                :items-per-page.sync="itemsPerPageProduct"
                @page-count="pageCountProduct = $event"
                hide-default-footer
              >
                <template v-slot:no-data>
                  <span class="text-center">ยังไม่มีข้อมูล</span>
                </template>

                <template v-slot:no-results>
                  <div class="text-center">ไม่พบข้อมูล</div>
                </template>

                <template v-slot:default="props">
                  <v-container fluid>
                    <v-row>
                      <v-col v-for="(item, index) in props.items" :key="index" cols="12" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card
                            :elevation="hover ? 12 : 2"
                            :class="{ 'on-hover': hover }"
                            style="transition: opacity 0.4s ease-in-out"
                            @click="handheldAddProduct(item)"
                          >
                            <v-img
                              :src="utils.getImage(item.product_picture)"
                              :lazy-src="item.product_picture"
                              aspect-ratio="1.2"
                              width="300"
                              class="align-end"
                            >
                              <v-expand-transition>
                                <div
                                  v-if="hover"
                                  class="d-flex transition-fast-in-fast-out v-card--reveal text-h2"
                                  style="height: 100%"
                                >
                                  <v-icon size="70" color="primary">mdi-cart-arrow-down </v-icon>
                                </div>
                              </v-expand-transition>
                              <template v-slot:placeholder>
                                <img
                                  class="rounded img-product"
                                  :src="utils.vimageError_Defalut(course.product_picture)"
                                />
                              </template>
                            </v-img>

                            <div class="text-sm pl-2 py-1 text-truncate">{{ item.name_th }}</div>
                            <div class="text-sm success--text pl-2 text-truncate">{{ formatInt(item.price) }} บาท</div>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-data-iterator>
              <div class="d-flex flex-row-reverse pt-2">
                <v-pagination
                  v-model="pageProduct"
                  :length="pageCountProduct"
                  :total-visible="7"
                  color="primary"
                ></v-pagination>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
    <v-col cols="4" md="4" sm="12">
      <ResultSelect :courseSend="selectCourse" :productSend="selectProduct" />
    </v-col>
  </v-row>
</template>

<script>
import ResultSelect from '@/components/POS/ResultSelect.vue'
import numeral from 'numeral'
import store from '@/store'
import utils from '@/helper/utils'
export default {
  components: { ResultSelect },
  data() {
    return {
      pageCourse: 1,
      searchCourse: '',
      sortByCourse: '',
      sortDescCourse: false,
      itemsPerPageCourse: 7,
      pageCountCourse: 0,
      headersCourse: [
        {
          text: 'ชื่อแพ็คเกจ',
          align: 'start',
          sortable: false,
          value: 'name_th',
          width: '35%',
        },
        { text: 'รหัสแพ็คเกจ', value: 'code' },
        { text: 'ราคา', value: 'price' },
        { text: '', value: 'action' },
      ],
      utils: utils,
      pageProduct: 1,
      searchProduct: '',
      sortByProduct: '',
      sortDescProduct: false,
      itemsPerPageProduct: 12,
      pageCountProduct: 0,
      headersProduct: [
        {
          text: 'ชื่อแพ็คเกจ',
          align: 'start',
          sortable: false,
          value: 'name_th',
          width: '35%',
        },
        { text: 'จำนวนยูนิต', value: 'amount' },
        { text: 'ราคาตั้งต้น', value: 'price' },
        { text: '', value: 'action' },
      ],
      course: [],
      product: [],
      selectCourse: [],
      selectProduct: [],
      amount: 1,
      tab: 0,
    }
  },
  mounted() {
    this.getListCourse()
    this.getListProduct()
    this.selectProduct = this.$store.getters.getProduct.length != 0 ? this.$store.getters.getProduct : []
    this.$root.$on('clearSelected', () => {
      this.selectCourse = []
      this.selectProduct = []
    })
  },
  methods: {
    formatFloat(number) {
      return numeral(number).format('0,0.00')
    },
    formatInt(number) {
      return numeral(number).format('0,0')
    },
    getListCourse() {
      this.$store.dispatch('getListCourse').then(res => {
        console.log(res)
        this.course = res
      })
    },
    getListProduct() {
      this.$store.dispatch('getListProduct').then(res => {
        console.log(res)
        this.product = res
      })
    },
    handheldAddCourse(item) {
      let price_total = ''
      price_total = { price_total: item.price, price_pay: item.price }

      Object.assign(item, price_total)
      console.log(this.selectCourse)
      if (this.$store.getters.getCourse.length != 0) {
        this.selectCourse = this.$store.getters.getCourse
      }
      this.$store.dispatch('getSalesList', { type: 3, id: item.id }).then(res => {
        let salesList = res.sales.map(i => {
          return {
            text: `${i.prefix_name ? i.prefix_name + ' ' : ''}${i.fname} ${i.lname}`,
            ...i,
          }
        })
        let providerList = res.doctors.map(i => {
          return {
            text: `${i.prefix_name ? i.prefix_name + ' ' : ''}${i.fname} ${i.lname}`,
            ...i,
          }
        })
        item.salesList = salesList
        item.providerList = providerList
        this.selectCourse.push({ ...item })
        this.$store.commit('SET_COURSE_ORDER', this.selectCourse)
      })
    },
    handheldAddProduct(item) {
      console.log(item)

      const result = this.selectProduct.findIndex(index => {
        if (index.id == item.id) {
          return index
        }
      })
      if (result != -1) {
        this.selectProduct[result].value = parseInt(this.selectProduct[result].value) + 1
        this.selectProduct.splice(this.selectProduct.length)
        Object.assign(item, { price_total: item.price * item.value, price_pay: item.price * item.value })
      } else {
        item.value = 1
        this.selectProduct.push(item)
        Object.assign(item, { price_total: item.price, price_pay: item.price * item.value })
      }

      if (this.$store.getters.getProduct.length != 0) {
        this.selectProduct = this.$store.getters.getProduct
      }
      this.$store.commit('SET_PRODUCT_ORDER', this.selectProduct)
      console.log(this.selectProduct)
      // this.$emit('SET_PRODUCT_ORDER', this.selectProduct)
    },
  },
  computed: {
    filteredProduct() {
      return this.product.filter(p => {
        return p.name_th.toLowerCase().includes(this.searchProduct.toLowerCase())
      })
    },
  },
}
</script>
<style scoped>
.img-member {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.img-product {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
