import logo from '@/assets/images/logos/Logo2.png'
import { configcode } from '@/config/bzbsconfig.js'
// const image = configcode.basePublic + '/users/default/default.png'
// const image = configcode.basePublic + "/app/screenDefault.png"
const image = configcode.basePublic + '/app/logo_app.png'
import image_default from '@/assets/images/Default-course.png'

export default {
  imageError(event) {
    console.log('🚀 ~ imageError ~ event:', event)
    event.target.src = image
  },
  vimageError(event) {
    return image
  },
  vimageError_Defalut(event) {
    return image_default
  },
  getImage(path) {
    if (path.includes('com')) {
      path = path.split('com')[1]
    }
    return configcode.basePublic + path
  },
}
